export const Store = () => {
  return (
    <div
      id="features"
      className="w-full  text-start md:p-10 p-6 mt-0  relative h-full flex justify-center items-center "
    >
      <div className="md:w-3/4 card h-auto  rounded-md flex md:flex-row flex-col ">
        <div className="p-10 flex flex-col gap-5">
          <p className="md:ml-10 text-[26px] font-[600]">
            Your store in seconds not hours or days.
          </p>
          <p className="md:ml-10 w-full "></p>
          <div className="w-full flex flex-row justify-start items-center gap-2">
            <div className="h-[20px] border border-primary w-1 bg-primary md:ml-10"></div>
            <p>Set up your store front</p>
          </div>
          <p className="md:w-full md:ml-10">
            We make it easy to design a beautiful online store without any
            coding knowledge.
          </p>
          <div className="w-full flex flex-row justify-start items-center gap-2">
            <div className="h-[20px] border border-primary w-1 bg-primary md:ml-10"></div>
            <p>Add your inventory</p>
          </div>
          <p className="md:w-full md:ml-10">
            Seamlessly manage your products with our easy-to-use inventory
            tools. Upload images, set prices, and add detailed descriptions to
            ensure your customers have all the information they need.
          </p>
          <div className="w-full flex flex-row justify-start items-center gap-2">
            <div className="h-[20px] border border-primary w-1 bg-primary md:ml-10"></div>
            <p>Launch</p>
          </div>
          <p className="w-full md:ml-10">
            Start selling and watch your business grow as your online store goes
            live.
          </p>
        </div>

        <div className=" w-full flex justify-end items-center ml-auto mt-auto">
          <img
            src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/agdi5uxx8naauuommokh"
            alt="store"
            className="md:w-[30vw]  w-full"
          />
        </div>
      </div>
    </div>
  );
};
