import { Link, useNavigate } from "react-router-dom";
import { Button } from "./button";
import { TiThMenuOutline } from "react-icons/ti";
import ScrollIntoView from "react-scroll-into-view";
export const Header = ({
  handleOpenDrawer,
}: {
  handleOpenDrawer: () => void;
}) => {
  const navigate = useNavigate();
  const location = window.location.pathname;

  return (
    <div className=" h-[70px] rounded-md m-auto  w-full fixed z-10 top-5 flex flex-row justify-center items-center p-5 ">
      <div className="md:w-1/2 w-full shadow-md relative h-full  bg-white card flex flex-row  p-6 rounded-full justify-end items-center ">
        <div
          onClick={() => {
            navigate("/");
          }}
          className="flex flex-row justify-start items-center cursor-pointer "
        >
          <img
            src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/rq5oa5gjbwztgkmuzg1k"
            alt="logo"
            className="w-11 h-11"
          />

          <p className="font-[500]">Blobcart</p>
        </div>
        <div className="flex flex-row items-center justify-end ml-auto gap-6">
          <ScrollIntoView selector="#features">
            <p
              onClick={() => {
                if (location !== "/") {
                  window.location.href = "/#features";
                }
              }}
              className="hover:text-primary font-[400] hidden  md:block"
            >
              Features
            </p>
          </ScrollIntoView>

          <Link
            to="/blog"
            className="hover:text-primary font-[400] hidden  md:block"
          >
            Blog
          </Link>
          <ScrollIntoView selector="#pricing">
            <p
              onClick={() => {
                if (location !== "/") {
                  window.location.href = "/#pricing";
                }
              }}
              className="hover:text-primary font-[400] hidden  md:block"
            >
              Pricing
            </p>
          </ScrollIntoView>
          <Button
            title="Login"
            action={() => {
              window.open("https://platform.blobcart.com", "_self");
            }}
            style={
              "p-2 px-4  rounded-full justify-center items-center hidden md:flex  bg-dark text-white hover:shadow-md cursor-pointer"
            }
          />
          <Button
            title="Sign up"
            action={() => {
              window.open("https://platform.blobcart.com/register", "_self");
            }}
            style={
              "p-2 px-4  rounded-full justify-center items-center hidden md:flex border-dark border-opacity-10 border  bg-white text-dark hover:shadow-md cursor-pointer"
            }
          />
          <TiThMenuOutline
            className="md:hidden cursor-pointer"
            onClick={() => handleOpenDrawer()}
          />
        </div>
      </div>
    </div>
  );
};
