import { Outlet } from "react-router-dom";
import { Header } from "./header";
import { Footer } from "./sections/footer";
import SideMenu from "./sidemenu";
import { useState } from "react";
export const Controller = () => {
  const [drawerState, toggleDrawer] = useState(false);
  const handleOpenDrawer = () => {
    toggleDrawer(true);
  };

  return (
    <div className="relative">
      <Header handleOpenDrawer={handleOpenDrawer} />
      <Outlet />
      <Footer />
      <SideMenu toggleDrawer={toggleDrawer} drawerState={drawerState} />
    </div>
  );
};
