import { Stack, SwipeableDrawer } from "@mui/material";
import { RiCloseCircleFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "./button";
import ScrollIntoView from "react-scroll-into-view";
export default function SideMenu({
  toggleDrawer,
  drawerState,
}: {
  toggleDrawer: (i: boolean) => void;
  drawerState: boolean;
}) {
  const navigate = useNavigate();
  const location = window.location.pathname;
  const login = () => {
    window.open("https://shop.blobcart.com", "_self");
  };
  const scheduleDemo = () => {
    navigate("/start");
  };
  return (
    <SwipeableDrawer
      anchor={"right"}
      open={drawerState}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      sx={{
        "& .MuiDrawer-paper": {
          p: 4,
          width: "100%",
          maxWidth: 312,
        },
      }}
    >
      <Stack
        sx={{ cursor: "pointer" }}
        direction="row"
        alignItems="center"
        gap={1}
        mb={4}
        onClick={() => toggleDrawer(!drawerState)}
      >
        <RiCloseCircleFill />
      </Stack>

      <ul className="flex flex-col gap-8 justify-around w-[100%]">
        <ScrollIntoView selector="#features">
          <li>
            <p
              onClick={() => {
                if (location !== "/") {
                  window.location.href = "/#features";
                }
                toggleDrawer(false);
              }}
              className="cursor-pointer hover:underline text-dark text-[20px] font-[500] font-[22px]"
            >
              Features
            </p>
          </li>
        </ScrollIntoView>

        <li>
          <Link
            to="/blog"
            onClick={() => {
              toggleDrawer(false);
            }}
            className=" cursor-pointer text-dark text-[20px]  font-[500] hover:underline"
          >
            Blog
          </Link>
        </li>
        <ScrollIntoView selector="#pricing">
          <li>
            <p
              onClick={() => {
                if (location !== "/") {
                  window.location.href = "/#pricing";
                }
                toggleDrawer(false);
              }}
              className="cursor-pointer hover:underline text-dark text-[20px] font-[500] font-[22px]"
            >
              Pricing
            </p>
          </li>
        </ScrollIntoView>
        <Button
          title="Get Started"
          action={login}
          style={
            "md:flex md:ml-auto  h-[50px]  p-6 bg-dark text-white  cursor-pointer font-[500]  rounded-md flex justify-center items-center "
          }
        />
      </ul>
    </SwipeableDrawer>
  );
}
